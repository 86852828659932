/* From Uiverse.io by mi-series */ 
.container-register {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
  }
  
  .form_area-register {
    margin-top: 40px;
    max-width: 350px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #FFD335;
    height: auto;
    border: 2px solid #264143;
    border-radius: 20px;
    box-shadow: 3px 4px 0px 1px #9B6143;
  }
  
  .title-register {
    color: #264143;
    font-weight: 900;
    font-size: 1.5em!important;
    margin-top: 20px;
  }
  .form_group-register {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    margin: 10px;
  }
  
  .sub_title-register {
    text-align: start;
    color: black;
    font-weight: 600;
    margin: 5px 0;
  }


  
  .form_style-register {
    outline: none;
    border: 2px solid #264143;
    box-shadow: 3px 4px 0px 1px #9B6143;
    width: 290px;
    padding: 12px 10px;
    border-radius: 4px;
    font-size: 15px;
    background-color: white!important;
  }
  
  .form_style-register:focus, .btn-register:focus {
    transform: translateY(4px);
    box-shadow: 1px 2px 0px 0px #9B6143;
  }
  
  .btn-register {
    color: black;
    padding: 15px;
    margin: 25px 0px;
    width: 290px;
    font-size: 15px;
    background: #9B6143;
    border-radius: 10px;
    font-weight: 800;
    box-shadow: 3px 3px 0px 0px #E99F4C;
  }
  
  .btn-register:hover {
    opacity: .9;
  }
  
  .link-register {
    font-weight: 800;
    color: #264143;
    padding: 5px;
  }