.exam-title {
  font-weight: bold;
  font-size: 20.8px;
}

.current-question {
  font-weight: bold;
  margin-bottom: 30px;
  font-size: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  p {
    width: fit-content;
    height: fit-content;
    display: flex;
    align-items: center;
  }
}

.current-question-degree {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 18px;
}

.current-question-answers,
.response-question-answers {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // justify-content: flex-start;
  gap: 16px;

  div {
    width: 100%;
    border-radius: 16px;
    padding: 9.6px;
    display: flex;
    justify-content: flex-start;
    gap: 16px;
    align-items: center;

    span {
      pointer-events: none;
      width: 30px;
      height: 30px;
      min-width: 30px;
      min-height: 30px;
      border-radius: 50%;
      border-radius: 50%;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 19px;
    }

    p {
      pointer-events: none;
      font-weight: bold;
      font-size: 19.2px;
      width: fit-content;
      height: fit-content;
      display: flex;
      align-items: center;
    }
  }
}

.current-question-answers {
  div {
    cursor: pointer;
    border: 2px solid #4285f4;
    transition-duration: 0.3s;

    span {
      border: 3px solid #4285f4;
    }
  }

  .selected,
  div:hover {
    background-color: #f9b522;
    border: 2px solid #f9b522;

    color: #4285f4;

    span {
      border: 3px solid #4285f4;
    }
  }
}

.response-question-answers {
  div {
    border: 2px solid #4285f4;

    span {
      border: 3px solid #4285f4;
    }
  }

  .response-selected {
    background-color: #4285f4;
    border: 2px solid #4285f4;

    color: #fef2f2;

    span {
      border: 3px solid #fef2f2;
    }
  }
}

.no-answer {
  color: #4285f4;
  font-weight: bold;
}

.hover-text {
  display: none;
}

.show-hover-text {
  display: block;
}

.dot-menu {
  transform: scaleY(0);
  background-color: #4285f4;
}

.hide-dot-menu {
  transform: scaleY(1);
}

.explanation-text {
  transform: scaleY(0);
}

.hide-explanation-text {
  transform: scaleY(1);
}

.h-0 {
  height: 0;
}

/* fixed */

.myfixed {
  //position: fixed;
  //z-index: 100;
  //opacity: 0;
}

.myquestions-container-relative {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.myabsolute-hide {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  background-color: #4285f4d9;
}

.myquestions-wrapper {
  display: flex;
  flex-direction: column;
  gap: 45px;
  align-items: center;
  //border-radius:45px;
}

.mytoggle-fixed {
  pointer-events: all;
  opacity: 1;
}

.mytoggle-QuestionsWrapper {
  transform: scale(1);
}

.myquestions-wrapper h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  //margin-top: 16px;
  //margin-bottom: 2rem;
  border-bottom: 2px solid rgb(199, 31, 56);
  padding-bottom: 2rem;

  @media screen and (max-width: 550px) {
    font-size: 1.2rem;
  }
}

.myactual-questions-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(45px, 1fr));
  gap: 32px;
  place-content: center;
  place-items: center;
}

.myquestion {
  border: 2px dashed #8A2A2A;
  color: #fef2f2;
  background-color: #8A2A2A;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  position: relative;
  border-radius: 0.3rem;
}

button[disabled] {
  cursor: no-drop;
}

button[disabled]:hover {
  transform: scale(1);
}

.selected-popup-question {
  background-color: #84cc16;
  border: 2px solid #84cc16;
  color: #fef2f2;
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 600;
  cursor: pointer;
  border-radius: 0.3rem;
}

.location-icon {
  position: absolute;
  top: -28px;
  left: 50%;
  transform: translateX(-50%);
  cursor: pointer;
  font-size: 24px;
  color: #4285f4;
}

.disable-timer {
  display: none;
}

.input-container {
  font-weight: bold;
  display: flex;
  justify-content: flex-end;

  span {
    display: inline-block;
    font-weight: normal;
    margin-top: 16px;
  }

  input {
    border: 2px solid #4285f4;
    border-radius: 0.5rem;
    margin-top: 32px;
    color: #4285f4;
    padding: 0.3rem;
    width: 190px;
    text-align: end;
  }

  input::placeholder {
    color: #4285f466;
    font-size: 14px;
  }

  .right-answer-span {
    //color: #84cc16;
  }
}

.result-container {
  h3 {
    font-weight: bold;
  }

  a {
    font-weight: bold;
    padding-inline: 16px;
    padding-block: 8px;
    background-color: #4285f4;
    color: #fef2f2;
    border: 2px solid #4285f4;
    transition: all 0.3s ease-in-out;
    border-radius: 11px;
  }

  a:hover {
    color: #4285f4;
    background-color: #fef2f2;
    border: 2px solid #4285f4;
  }
}

.h-questions {
  height: calc(100vh - 128px);
  max-height: calc(100vh - 128px);
}

/**
//!-----------------slider-----------------
*/
.slick-slide>div {
  margin-inline: 25px;
  display: flex;
  justify-content: center;
}

.response-question-input {
  color: #4285f4;
}