@tailwind base;
@tailwind components;
@tailwind utilities;

table .ant-checkbox-wrapper {
  justify-content: center;
}


.landingPage{
  background: linear-gradient(180deg, #8A2A2A 0%, #1D3C56 50% , #8A2A2A 100%);
}

/* From Uiverse.io by Victan78 */ 
.loader2 {
  width: 100px;
  height: 100px;
  border: solid 4px #8A2A2A;
  border-top: solid 5px #FFD335;
  border-radius: 50%;
  transition: all 0.5s;
  animation: rotation_51512 1.2s infinite cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

@keyframes rotation_51512 {
  70% {
    box-shadow: 0px 0px 10px 50px #8A2A2A;
  }

  90% {
    box-shadow: 0px 0px 10px 50px #8A2A2A;
  }

  100% {
    opacity: 0.5;
    transform: rotate(360deg);
  }
}
