.signin-inputs,
.submit,
textarea,
select {
  border: 2px solid transparent;
  padding-left: 0.8em;
  outline: none;
  overflow: hidden;
  background-color: #F3F3F3;
  border-radius: 10px;
  transition: all 0.5s;
  width: 100%;
  min-height: 48px;
}

.signin-inputs,
.submit {
  height: 3.4rem;
}

.submit {
  text-align: center;
}

.signin-inputs::placeholder,
textarea::placeholder {
  color: #4285f466;
  opacity: 0.5;
  font-size: 1.5rem;
  font-weight: 600;
}

.signin-inputs:focus,
textarea:focus,
select:focus,
.signin-inputs:hover,
textarea:hover,
select:hover {
  border: 2px solid #4A9DEC;
  box-shadow: 0px 0px 0px 7px rgb(74, 157, 236, 20%);
  background-color: white;
}

label {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
  width: 100%;
}

.submit {
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  font-size: 1.7rem;
  font-weight: 700;
}

.submit:active {
  transform: scale(0.9);
}

.submit:disabled {
  border: 2px solid #4285f466;
  color: #4285f466;
}

.submit:disabled:active {
  transform: scale(1);
}

input[type="file"] {
  height: 3.4rem;
  padding: 0.4rem;
  font-size: 1.2rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.question-submit {
  width: 250px;
}

//!------ permissions checkbox ---------

.checkbox-wrapper {
  position: relative;
}

.checkbox-wrapper>svg {
  position: absolute;
  top: -130%;
  left: -170%;
  width: 110px;
  pointer-events: none;
}

.checkbox-wrapper * {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.checkbox-wrapper input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  margin: 0;
}

.checkbox-wrapper input[type="checkbox"]:focus {
  outline: 0;
}

.checkbox-wrapper .cbx {
  width: 24px;
  height: 24px;
  top: calc(100px - 12px);
  left: calc(100px - 12px);
}

.checkbox-wrapper .cbx input {
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px !important;
  min-height: 24px !important;
  border: 2px solid #4285f4;
  background-color: rgba(66, 133, 244, 0.4);
  border-radius: 50%;
}

.checkbox-wrapper .cbx label {
  width: 24px;
  height: 24px;
  background: none;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

.checkbox-wrapper .cbx svg {
  position: absolute;
  top: 5px;
  left: 4px;
  z-index: 1;
  pointer-events: none;
}

.checkbox-wrapper .cbx svg path {
  stroke: #fef2f2;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-dasharray: 19;
  stroke-dashoffset: 19;
  -webkit-transition: stroke-dashoffset 0.3s ease;
  transition: stroke-dashoffset 0.3s ease;
  -webkit-transition-delay: 0.2s;
  transition-delay: 0.2s;
}


.checkbox-wrapper .cbx input:checked+label {
  -webkit-animation: splash-12 0.6s ease forwards;
  animation: splash-12 0.6s ease forwards;
}

.checkbox-wrapper .cbx input:checked+label+svg path {
  stroke-dashoffset: 0;
}

.checkbox-wrapper.wrong .cbx input:checked+label {
  -webkit-animation: splash-12-wrong 0.6s ease forwards;
  animation: splash-12-wrong 0.6s ease forwards;
}

.checkbox-wrapper.right .cbx input:checked+label {
  -webkit-animation: splash-12-wrong 0.6s ease forwards;
  animation: splash-12-right 0.6s ease forwards;
}

.react-tel-input input {
  width: 100%;
  height: 100%;
  background-color: transparent;
}

@-webkit-keyframes splash-12 {
  40% {
    background: #4285f4;
    -webkit-box-shadow:
      0 -18px 0 -8px #4285f4,
      16px -8px 0 -8px #4285f4,
      16px 8px 0 -8px #4285f4,
      0 18px 0 -8px #4285f4,
      -16px 8px 0 -8px #4285f4,
      -16px -8px 0 -8px #4285f4;
    box-shadow:
      0 -18px 0 -8px #4285f4,
      16px -8px 0 -8px #4285f4,
      16px 8px 0 -8px #4285f4,
      0 18px 0 -8px #4285f4,
      -16px 8px 0 -8px #4285f4,
      -16px -8px 0 -8px #4285f4;
  }

  100% {
    background: #4285f4;
    -webkit-box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
    box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12 {
  40% {
    background: #4285f4;
    -webkit-box-shadow:
      0 -18px 0 -8px #4285f4,
      16px -8px 0 -8px #4285f4,
      16px 8px 0 -8px #4285f4,
      0 18px 0 -8px #4285f4,
      -16px 8px 0 -8px #4285f4,
      -16px -8px 0 -8px #4285f4;
    box-shadow:
      0 -18px 0 -8px #4285f4,
      16px -8px 0 -8px #4285f4,
      16px 8px 0 -8px #4285f4,
      0 18px 0 -8px #4285f4,
      -16px 8px 0 -8px #4285f4,
      -16px -8px 0 -8px #4285f4;
  }

  100% {
    background: #4285f4;
    -webkit-box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
    box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12-wrong {
  40% {
    background: #f00;
    -webkit-box-shadow:
      0 -18px 0 -8px #f00,
      16px -8px 0 -8px #f00,
      16px 8px 0 -8px #f00,
      0 18px 0 -8px #f00,
      -16px 8px 0 -8px #f00,
      -16px -8px 0 -8px #f00;
    box-shadow:
      0 -18px 0 -8px #f00,
      16px -8px 0 -8px #f00,
      16px 8px 0 -8px #f00,
      0 18px 0 -8px #f00,
      -16px 8px 0 -8px #f00,
      -16px -8px 0 -8px #f00;
  }

  100% {
    background: #f00;
    -webkit-box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
    box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
  }
}

@keyframes splash-12-right {
  40% {
    background: #008000;
    -webkit-box-shadow:
      0 -18px 0 -8px #008000,
      16px -8px 0 -8px #008000,
      16px 8px 0 -8px #008000,
      0 18px 0 -8px #008000,
      -16px 8px 0 -8px #008000,
      -16px -8px 0 -8px #008000;
    box-shadow:
      0 -18px 0 -8px #008000,
      16px -8px 0 -8px #008000,
      16px 8px 0 -8px #008000,
      0 18px 0 -8px #008000,
      -16px 8px 0 -8px #008000,
      -16px -8px 0 -8px #008000;
  }

  100% {
    background: #008000;
    -webkit-box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
    box-shadow:
      0 -36px 0 -10px transparent,
      32px -16px 0 -10px transparent,
      32px 16px 0 -10px transparent,
      0 36px 0 -10px transparent,
      -32px 16px 0 -10px transparent,
      -32px -16px 0 -10px transparent;
  }
}