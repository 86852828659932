.singleQuestionWrapper {
  p {
    font-weight: 600;
    font-size: 1.8rem;
  }

  img {
    object-fit: contain;
  }

  border: 1px solid #4285f4;
  border-radius: 4rem;
  padding: 2rem;
}

.optionsQuestionWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: center;
  gap: 5rem;
  width: 100%;

  @media screen and (max-width: 772px) {
    flex-direction: column;
    gap: 3rem;
  }
}

.optionsPostWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex-direction: column-reverse;
  gap: 3rem;
}

.questionOptions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  width: fit-content;

  @media screen and (max-width: 772px) {
    justify-content: center;

    width: 100%;
    border-bottom: 2px solid #4285f4;
    padding-bottom: 2rem;
  }
}

.postOptions {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 1.8rem;
}

.deleteQuestionBtn,
.editQuestionBtn {
  border-radius: 1rem;
  border-width: 2px;
  padding: 0.5rem;
  text-align: center;
  font-weight: 600;
  color: rgb(254 242 242);
  transition-duration: 300ms;

  &:active {
    transform: scale(0.9);
  }
}

.deleteQuestionBtn {
  border-color: #4285f4;
  background-color: #4285f4;

  &:hover {
    background-color: #4285f433;
    color: #4285f4;
  }
}

.editQuestionBtn {
  border-color: #0b9d58;
  background-color: #0b9d58;

  &:hover {
    background-color: #0b9d5833;
    color: #0b9d58;
  }
}

.categoryQuestionWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (max-width: 772px) {
    align-items: center;
  }
}

.PostWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.1rem;
  align-items: center;
}

.essayTitleWrapper {
  display: flex;
  gap: 2rem;
  align-items: center;

  span,
  h4 {
    border: 2px solid #4285f4;
    border-radius: 1rem;
    padding: 0.1rem 0.5rem;
  }

  span {
    background-color: #fecacab3;
  }
}

.categoryAnswersWrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  margin-top: 3rem;
  border-top: 2px solid;
  padding-top: 2rem;
}

.categorySingleAnswer {
  width: 50%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;

  @media screen and (max-width: 772px) {
    width: 100%;
    align-items: center;
  }
}

.answer1Wrapper {
  @media screen and (max-width: 772px) {
    order: 1;
  }
}

.answer2Wrapper {
  @media screen and (max-width: 772px) {
    order: 2;
  }
}

.answer3Wrapper {
  @media screen and (max-width: 772px) {
    order: 3;
  }
}

.answer4Wrapper {
  @media screen and (max-width: 772px) {
    order: 4;
  }
}

.correctSingleAnswer {
  border: 2px solid #00ff80;
  border-radius: 2rem;
  background-color: #d5ffea5d;
  color: #00ff80;
}

.hide {
  display: none;
}